<template lang="pug">
	div
		el-form
			el-row(:gutter="10" type="flex" justify="space-between")
				el-col(:xs="24" :sm="12", :lg="6")
					el-form-item(label="By Keyword" label-position="top" label-width="auto")
						el-input(type="text", placeholder="Search By Keyword...", size="mini", v-model="pagination.keyword", v-debounce:300ms="fetchData")
				el-col(:xs="24" :sm="12", :lg="6")
					el-button(style="float:right" type="success" icon="el-icon-plus" size="mini" @click="$router.push({name: 'iframe-add-control-group'})") Add Control Group

			el-row(:gutter="24")
				el-col(:xs="24" :lg="12")

					el-table.niche-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
						el-table-column(align='center', label='#' :width='55')
							template(slot-scope='scope')
								| {{ scope.row.id }}
						el-table-column(align="center" label="Name")
							template(slot-scope="scope")
								span {{ scope.row.name }}&nbsp;
								small.text-success {{ scope.row.notes ? `(${scope.row.notes})` : '' }}
						el-table-column(align="center" label="Associated Geos")
							template(slot-scope="scope")
								el-tooltip(v-if="scope.row.geos.length > 0")
									template(slot="content")
										div(v-for="(geo, idx) of scope.row.mapped")
											label.d-block <b>{{ scope.row.geos[idx] }}</b> - {{ geo }}
											//br
											//label.d-block <b>Name</b>: {{ geo.name }}

									span.text-info.chooseable Hover To See
								span.text-info(v-else="") No Geos
						el-table-column(align="center" label="Associated Brands")
							template(slot-scope="scope")
								el-tooltip(v-if="scope.row.brands.length > 0")
									template(slot="content")
										div(v-for="brand of scope.row.brands")
											label.d-block <b>{{'#' + brand.id}}</b> - {{ brand.name }}

											//br
									span.text-info.chooseable Hover To See
						el-table-column(align="center" label="Associated Integrations")
							template(slot-scope="scope")
								el-tooltip(v-if="scope.row.integrations.length > 0")
									template(slot="content")
										div(v-for="integration of scope.row.integrations")
											label.d-block <b>{{'#'+integration.id}}</b>: {{ integration.type }}&nbsp;
												small.text-success {{ integration.note ? `(${integration.note})` : '' }}
											//br
									span.text-info.chooseable Hover To See
						el-table-column(align="center" label="Action" width="180px")
							template(slot-scope='scope')
								.d-flex.justify-content-center
									el-button.small-icon(v-b-tooltip.html.hover.top="", title="Clone Control Group", type="warning", icon="el-icon-star-on", size="small", @click="$router.push({name: 'iframe-add-control-group', query: { id: scope.row.id }})")
									el-button.small-icon(v-b-tooltip.html.hover.top="", title="Show Control Group Working Hours", type="success", icon="el-icon-guide", size="small", @click="showSettings(scope.row)")
									el-button.small-icon(v-b-tooltip.html.hover.top="", title="Edit This control group details, attach entities or change working hours", type="info", icon="el-icon-tickets", size="small", @click="$router.push({name: 'iframe-edit-control-group', params: { id: scope.row.id }})")
									el-button.small-icon(v-b-tooltip.html.hover.top="", title="Delete This Control Group", type="danger", icon="el-icon-delete", size="small", @click="remove(scope.row.id)")
					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="pagination.page", :total-rows="count", :per-page="pagination.limit", aria-controls="data-list", @change="fetchData")

						el-row.mt-4
							el-col(:span="24")
								label.label-text-gray
									b Total:&nbsp;
									| {{ count | numeral('0,0') }}

						el-row
							el-col(:span="24")
								el-select.mt-3(filterable="", v-model="pagination.limit", @change="fetchData")
									el-option(label="10 Per Page", :value="5")
									el-option(label="10 Per Page", :value="10")
									el-option(label="20 Per Page", :value="20")
									el-option(label="50 Per Page", :value="50")
									el-option(label="100 Per Page", :value="100")


				el-col(:xs="24" :lg="12")
					.text-center.mt-3(v-if="!current_group")
							h3 To view the settings, choose a control group from the list
							h5 Currently, nothing is selected...
					div(v-else="")
						working-hours-grid( :control_group="current_group")
					el-row.mt-4(v-if="current_group")
						el-col(:span="24")
							label.label-description
								b
									| Current Group: {{ '#' + current_group.id }}
									| {{ current_group.name }}&nbsp;
									small ({{ current_group.notes }})
								p This is the working hours of the selected control group. This table is read-only however.

</template>


<style lang="scss">

</style>

<style lang="scss" scoped>
.small-icon {
	padding: 9px;
}
.label-description {
	margin: 0;
	font-size: 14px;
	color: #909399;
}
</style>

<script>
import WorkingHoursGrid from "@/components/working-hours-grid.vue";

export default {
	name: 'control-groups-list',
	components: {WorkingHoursGrid},
	data() {
		return {
			list: [],
			count: 0,
			busy: false,
			current_group: '',
			pagination: {
				keyword: '',
				page: 1,
				limit: 10
			}
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		showSettings(group) {
			this.current_group = group
		},
		fetchData() {
			this.busy = true;
			setTimeout(() => {
				const body = { ...this.pagination}
				this.$apix.sendHttpRequest('GET', 'control-group/list', body)
						.then(res => {
							this.list = res.rows;
							this.count = res.count;
						})
						.finally(() => {
							this.busy = false;
						})
			}, 300)

		},
		remove(id) {
			this.$confirm('Are you sure you want to delete this control group?', 'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(() => {
				this.$apix.sendHttpRequest('DELETE', `control-group/delete/${id}`)
						.then(() => {
							this.fetchData();
						})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: 'Delete canceled'
				});
			});
		}
	}
}
</script>