<!-- CustHorizScroll.vue -->
<template>
	<div class="cust-horiz-scroll-wrapper">
		<!-- Top Scrollbar -->
		<div
				class="top-scrollbar"
				ref="topScrollbar"
				@scroll="handleScroll('top')"
		>
			<div class="scroll-content" :style="{ width: scrollWidth + 'px' }"></div>
		</div>

		<!-- Main Content with Bottom Scrollbar -->
		<div
				class="main-scrollbar"
				ref="mainScrollbar"
				@scroll="handleScroll('main')"
		>
			<div ref="contentWrapper" class="content-wrapper">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<style scoped>
.cust-horiz-scroll-wrapper {
	position: relative;
	width: 100%;
}

.top-scrollbar {
	overflow-x: auto;
	overflow-y: hidden;
	height: 16px; /* Adjust based on scrollbar height */
}

.scroll-content {
	height: 1px; /* Minimal height to enable scrollbar */
}

.main-scrollbar {
	overflow-x: auto;
	/* Optional: Add margin-top to prevent overlap */
	margin-top: 2px;
}

.content-wrapper {
	display: inline-block;
	/* Ensure the content takes full width to allow accurate measurement */
}
</style>

<script>
import ResizeObserver from 'resize-observer-polyfill'; // Install via npm if needed

export default {
	name: 'CustHorizScroll',
	data() {
		return {
			scrollWidth: 0, // Width of the scrollable content
			isSyncingScroll: false, // Flag to prevent recursive scrolling
			resizeObserver: null, // To observe changes in content size
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.updateScrollWidth();
			this.setupResizeObserver();
			this.debouncedUpdateScrollWidth = this.debounce(this.updateScrollWidth, 300);
			window.addEventListener('resize', this.debouncedUpdateScrollWidth);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.debouncedUpdateScrollWidth);
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
	},
	methods: {
		/**
		 * Handles the scroll event for both top and main scrollbars.
		 * @param {String} source - Indicates which scrollbar triggered the event ('top' or 'main').
		 */
		handleScroll(source) {
			if (this.isSyncingScroll) return;

			this.isSyncingScroll = true;

			const topScroll = this.$refs.topScrollbar;
			const mainScroll = this.$refs.mainScrollbar;

			if (source === 'top') {
				mainScroll.scrollLeft = topScroll.scrollLeft;
			} else if (source === 'main') {
				topScroll.scrollLeft = mainScroll.scrollLeft;
			}

			this.isSyncingScroll = false;
		},

		/**
		 * Updates the scrollable width based on the main content's scrollWidth.
		 */
		updateScrollWidth() {
			this.$nextTick(() => {
				const contentWrapper = this.$refs.contentWrapper;
				if (contentWrapper) {
					this.scrollWidth = contentWrapper.scrollWidth;
					console.log('Updated scrollWidth:', this.scrollWidth);
				}
			});
		},

		/**
		 * Debounce function to limit how often a function can fire.
		 * @param {Function} func - Function to debounce.
		 * @param {Number} wait - Wait time in milliseconds.
		 * @returns {Function} - Debounced function.
		 */
		debounce(func, wait, immediate) {
			let timeout;
			return function () {
				const context = this,
						args = arguments;
				const later = function () {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};
				const callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) func.apply(context, args);
			};
		},

		/**
		 * Sets up a ResizeObserver to monitor changes in the content's size.
		 */
		setupResizeObserver() {
			if (ResizeObserver) {
				this.resizeObserver = new ResizeObserver(() => {
					this.updateScrollWidth();
				});
				this.resizeObserver.observe(this.$refs.contentWrapper);
			} else {
				// Fallback for browsers that do not support ResizeObserver
				// Consider using MutationObserver or polling as needed
				console.warn('ResizeObserver is not supported by your browser.');
			}
		},
	},
};
</script>