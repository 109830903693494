<template lang="pug">
	#status-mapping-root
		el-form(:form="form" :model="form" :rules="rules" ref="form")
			el-row.text-left
				h4 Common Utils & Tools
				p You can find various utils that may help you in different situations. The sections are separated by topics.

			el-divider
			el-row.text-left
				h5 Encrypt / Decrypt
				p Use this tool to encrypt or decrypt a string. Use with care!!

			el-row(:gutter="24")
				el-col(:xs="24" :sm="12" :md="6")
					el-form-item(prop="topic" )
						template(slot="label")
							label Topic&nbsp;
							small(v-bind:class="{'text-success':form.action, 'text-danger': !form.action}")
								i (Current action - <b>{{form.action ? 'Encrypt' : 'Decrypt'}}</b>)
						el-input(type="textarea" :rows="3" :placeholder="`Enter a string to${form.action ? 'encrypt' : 'decrypt'}`" v-model="form.topic")
				el-col(:xs="24" :sm="12" :md="6")
					el-form-item()
						template(slot="label")
							label Result&nbsp;
							small(v-bind:class="{'text-success':form.action, 'text-danger': !form.action}")
								i (<b>{{form.action ? 'Encrypt' : 'Decrypt'}}ed</b> String - Read Only)
						el-input(type="textarea" :rows="3" :placeholder="`${form.action ? 'encrypt' : 'decrypt'}ion result will be pasted here`" readonly="" :value="cryptResult")

				el-col(:xs="12" :sm="8" :md="6")
					el-form-item(label="Action")
						el-switch.w-100(v-model="form.action" active-text="Encrypt" inactive-text="Decrypt"	inactive-color="#ce1313")

				el-col(:span="24")
					.d-flex.justify-content-start.mt-3
						el-button(type="info" :icon="`el-icon-${form.action ? 'success' : 'error'}`" @click="encryptionHandler()") {{form.action ? 'Encrypt' : 'Decrypt'}}
			el-divider
			el-row

			el-row.text-left(:gutter="48")
				// CURRENTLY IT IS HERE - CONSIDER MOVING IT TO ANOTHER PLACE THAT IS ACCESSIBLE TO THE WORKERS!!!!!
				el-col(:xs="24" :md="12" :xl="8")
					h5 Geo Location Finder
					p Using tool will help you to find the location of an IP address. Works with both IPv4 and IPv6 addresses. The tool uses datasets from the following vendors and merges between them in case of missing mappings:
						ul.mt-3
							li MaxMind&nbsp;
								small.text-success (GeoLite2)
							li IP2Location&nbsp;
								small.text-success (Lite)
							li DB-IP&nbsp;
								small.text-success (Lite)

					el-form-item(label="IP Address")

						el-input(v-model="form.ip" placeholder="Enter an IP address")
							el-button(slot="append" icon="el-icon-search" @click="geoIpGuess")
				el-col(:xs="24" :md="12" :xl="10")
					h5 Mapping Results
					el-table(:data="form.currentGeoData"  fit="" highlight-current-row="")
						el-table-column(label="Topic" prop="topic")
						el-table-column(label="Content" prop="content")

			el-divider
			el-row.text-left
				h5 Services Control
				p Functionality to control services. Use with care!!
			el-row.text-left()
				h5 Refresh Services
				p Use the folowwing buttons to send an "acknowledge" to all the registration services. You got 2 options - either to send a soft refresh or a hard refresh.&nbsp;
					| The difference between the two is that the soft refresh will take some time to complete, while the hard refresh will be done immediately.
			el-row(:gutter="24")
				el-col(:xs="24" :sm="12" :md="6")
					.d-flex.justify-content-start.mt-3
						el-button(type="success" @click="refreshServices('soft')") Soft Refresh
						el-button(type="warning" @click="refreshServices('hard')") Hard Refresh
</template>


<script>
import {notEmpty, validateIP} from "@/utils/validate";

export default {
	name: 'common-utils',
	data() {
		return {
			form: {
				topic: '',
				action: false,
				ip: '',
				currentGeoData: []
			},
			cryptResult: '',
			rules: {
				topic: { required: false, validator: notEmpty, trigger: 'blur' },
				ip: { required: false, validator: validateIP, trigger: 'blur' }
			}
		}
	},
	methods: {
		refreshServices(type) {
			let loader = this.$loading.show();
			this.$apix.sendHttpRequest('POST', 'utils/refresh-services', {type})
					.then(res => {
						setTimeout(() => {
							this.$notify.success({
								title: 'API Response',
								message: `Services refreshed successfully (${type})`
							});
							loader.hide()
						}, 3000)
					})
					.catch(e => {
						setTimeout(() => {
							console.error('Error refreshing services', e);
							loader.hide()
						}, 3000)
					})
		},
		encryptionHandler() {
			this.$refs.form.validate(valid => {
				if (!valid) return this.$message.error('Validation failed');
				const {topic, action} = this.form;
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('POST', 'utils/crypt', {topic, action})
						.then(res => {
							this.$notify.success({
								title: 'API Response',
								message: (action ? 'Encrypt' : 'Decrypt') + 'ion completed successfully'
							});
							this.cryptResult = res;
							loader.hide()
						})
						.catch(e => loader.hide())
			});
		},
		geoIpGuess() {
			this.form.currentGeoData = [];
			let loader = this.$loading.show();
			this.$apix.sendHttpRequest('GET', 'geo/by-ip', {ip: this.form.ip})
					.then(res => {
						loader.hide()
						Object.entries(res).forEach((e, i) => {
							this.form.currentGeoData.push({topic: e[0], content: e[1]})
						});
						this.$notify.success({
							title: 'API Response',
							message: 'Geo location found'
						})
					})
					.catch(e => {
						loader.hide();
						this.$notify.error({
							title: 'API Response',
							message: 'No data found'
						})
					})
		},
	}
}
</script>